import listInstance from 'store/modules/listInstance'
import listHelperMixin from '@/components/list/mixins/listHelperMixin'
export default {
  props: {
    popupConfig: {
      type: Object,
      default: () => null,
    },
    listInForm: {
      type: Boolean,
      default: false,
    },
    // 當列表為表單元件(list-component)時, 外部將注入此表單Key屬性
    containerFormKey: {
      type: String,
      default: null,
    },
    // 當列表為表單元件(list-component)時, 外部將注入此屬性
    listInFormIndexParams: {
      type: Function,
      default: null
    },
  },
  metaInfo() {
    if(!this.bindRoute) return null
    return {
      title: this.pageTitle,
    }
  },
  mixins: [listHelperMixin],
  data: (vm) => ({
    listKey: `list-${vm._uid}`,
    hasInit: false,
    bindRoute: true,
    pageTitle: '',
  }),
  async created() {
    this._initVuexModule()
    await this._initList()
  },
  methods: {
    async indexApi(params) {
      // TODO
    },
    getListConfig() {
      return {
        get: (vm) => {},
      }
    },
    async _initList() {
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'popupConfig',
        value: this.popupConfig,
      })

      this.$store.dispatch(`list/${this.listKey}/setApi`, {
        indexApi: this.indexApi,
        patchApi: this.patchApi,
        deleteApi: this.deleteApi,
        batchApi: this.batchApi,
        batchDeleteApi: this.batchDeleteApi,
        exportApi: typeof this.exportApi === 'function' ? this.exportApi : this.indexApi,
      })

      this.$store.dispatch(`list/${this.listKey}/setActions`, {
        index: this._indexAction,
        changeIndexCondition: this._changeIndexCondition,
      })

      this.setupPageTitle()
      await this._setupMeta()
      this._setStoreByRoute()
      await this._indexAction()
    },
    async _setupMeta() {
      if(typeof this.beforeIndex != 'function') {
        this.$store.dispatch(`list/${this.listKey}/setMeta`, null)
        return
      }
      try {
        await this.beforeIndex()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch(`list/${this.listKey}/setMeta`, window.eagleLodash.cloneDeep(this.meta))
      }
    },
    // 異動index條件: 搜尋、篩選、分頁、每頁筆數
    async _changeIndexCondition(scrollTop = false) {
      this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
      await this.$nextTick()

      // 無綁定route, 直接執行index
      if(!this.hasBindRouteFeature) {
        await this._indexAction({ scrollTop })
        return
      }

      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'scrollTopAfterIndex',
        value: scrollTop,
      })

      // 透過改route觸發index
      this.$router.push({
        query: this.listRouteQuery,
      })
    },
    async _indexAction(options = { scrollTop: false }) {
      this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
      this.$store.dispatch('loading/active')
      this.$store.dispatch('loading/progress')

      try {
        const result = await this.indexApi(this.indexParams)
        this.$store.dispatch(`list/${this.listKey}/setResult`, result)
        if(options.scrollTop === true) {
          this.scrollListDataTop()
        }
        this._afterIndexSuccessfully()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('loading/close')
        this.$store.dispatch('loading/closeProgress')
      }
      this.updateIndexParamsToStore()
    },
    updateIndexParamsToStore() {
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'indexParams',
        value: this.indexParams,
      })
    },
    _initVuexModule() {
      this.hasInit = false
      if(this._hasModule()) {
        this._destroyVuexModule()
      }
      if(this._hasModule() === false) {
        const options = {
          bindRoute: this.hasBindRouteFeature,
          config: this.getListConfig().get(this, this.listKey),
        }
        options.hasWritePermission = this.checkHasWritePermission(options.config)
        // 無寫入權限時直接禁用create, batch參數
        if(!options.hasWritePermission) {
          delete options.config.create
          delete options.config.batch
        }
        this.$store.registerModule(['list', this.listKey], listInstance(options))
      }
      this.hasInit = true
    },
    // 路由模式：列表初始化後, 或路由異動後, 由路由解析初請求相關資料寫回store並觸發index
    _setStoreByRoute() {
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'searchKeyword',
        value: this.parseRouteQuery.search || null,
      })

      if(this.parseRouteQuery.page && this.parseRouteQuery.page != this.page) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'page',
          value: this.parseRouteQuery.page,
        })
      }

      if(this.parseRouteQuery.per && this.parseRouteQuery.per != this.perPage) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'perPage',
          value: this.parseRouteQuery.per,
        })
      }

      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'filter',
        value: typeof this.parseRouteQuery.filter === 'object' ? this.parseRouteQuery.filter : null,
      })

      if(this.parseRouteQuery.sort && typeof this.parseRouteQuery.sort === 'object') {
        const sortKey = Object.keys(this.parseRouteQuery.sort)[0]
        const sortType = this.parseRouteQuery.sort[sortKey]
        if(this.sortKey != sortKey) {
          this.$store.dispatch(`list/${this.listKey}/set`, {
            key: ['sort', 'key'],
            value: sortKey,
          })
        }

        if(this.sortType != sortType) {
          this.$store.dispatch(`list/${this.listKey}/set`, {
            key: ['sort', 'type'],
            value: sortType,
          })
        }
      }

      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'displayMode',
        value: this.parseRouteQuery.display == 'card' ? 'card' : 'table',
      })

    },
    async _onRouteChange(after, before) {
      if(!this.hasBindRouteFeature) return
      if(after.fullPath == before.fullPath) return
      await this._setupMeta()
      this._setStoreByRoute()
      await this.$nextTick()
      await this._indexAction({
        scrollTop: this.scrollTopAfterIndex,
      })
    },
    async _afterIndexSuccessfully() {
      this.setupPageTitle()
    },
    setupPageTitle() {
      if(!this.listConfig.pageTitle) return

      if(typeof this.listConfig.pageTitle === 'function') {
        const pageTitle = this.listConfig.pageTitle(this.listMeta)
        this.pageTitle = this.$t(pageTitle)
        return
      }

      this.pageTitle = this.$t(this.listConfig.pageTitle) || ''
    },
  },
  computed: {
    hasBindRouteFeature() {
      if(this.popupConfig) return false
      return this.bindRoute
    },
    listRouteQuery() {
      let query = {}
      if(this.page != 1) {
        query.page = this.page
      }

      if(this.perPage != 20) {
        query.per = this.perPage
      }

      if(this.searchKeyword) {
        query.search = this.searchKeyword
      }

      if(this.listFilter) {
        query.filter = this._serialize(this.listFilter)
      }

      if(this.listRouteQueryHasSort) {
        query.sort = this._serialize({
          [this.sortKey]: this.sortType,
        })
      }

      if(this.displayMode == 'card') {
        query.display = 'card'
      }

      if(this.customRouteQuery) {
        query = window.window.eagleLodash.merge(query, this.customRouteQuery)
      }

      if(window.eagleLodash.isEmpty(query)) return null
      return query
    },
    displayMode() {
      return this.$store.getters[`list/${this.listKey}/displayMode`]
    },
    indexParams() {
      const params = {
        page: this.page,
        per: this.perPage,
      }

      if(this.searchKeyword) {
        params.q = this.searchKeyword
      }

      if(this.listFilter) {
        params.filter = this.listIndexFilter
      }

      if(this.sortKey) {
        params.sort = { [this.sortKey]: this.sortType }
      }

      if(typeof this.listInFormIndexParams === 'function') {
        return this.listInFormIndexParams(params, window.eagleLodash.cloneDeep(this.containerFormData))
      }

      return params
    },
  },
}
